<template>
  <div class="apple_ios">
    <img id="myButton" @click="getTo" src="@/assets/images/banner.jpg" alt />
    <img class="apple_androidimg" @click="getTo" src="@/assets/images/bottom.png" alt />
  </div>
</template>

<script>
import { reactFacekookPixel } from "@/utils/facebook.js";
export default {
  data() {
    return {};
  },
  created() {
    reactFacekookPixel("ViewContent", {
      currency: "触发成功",
      value: "触发成功"
    });
  },
  methods: {
    getTo() {
      window.location.href = "https://rcf-img-hk.img34348west009cn09.com/platformgj/2350/INR277/1003IDBets-1.0.0.1.apk";
    }
  }
};
</script>

<style lang="less">
@import "~@/style/cs1.css";
</style>