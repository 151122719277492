<template>
  <div>
    <ios v-if="isIOS"></ios>
    <android v-else></android>
  </div>
</template>

<script>
import ios from "@/components/ios.vue";
import android from "@/components/android.vue";
export default {
  data() {
    return {
      isIOS: false
    };
  },
  created() {
    this.checkPlatform();
  },
  watch: {},
  methods: {
    checkPlatform() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (
        userAgent.match(/iPad/i) ||
        userAgent.match(/iPhone/i) ||
        userAgent.match(/iPod/i)
      ) {
        this.isIOS = true;
      } else if (userAgent.match(/Android/i)) {
        this.isIOS = false;
      }
    }
  },
  components: {
    android,
    ios
  }
};
</script>

<style lang="less" scoped>
</style>